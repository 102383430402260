










































































import {Component, Ref, Vue} from 'vue-property-decorator';
import PersistentDialog from "@/components/global/dialog/PersistentDialog.vue";
import NoRobot from "@/components/global/NoRobotV2.vue";
import {setSid} from "@/assets/utils/SessionUtils";
import BrowserUtility from "@/mixin/browserUtility";
import ViewDesign from "@/components/global/ViewDesign.vue";
import {globalEventBus} from "@/main";

@Component({
    components: {PersistentDialog,NoRobot,ViewDesign},
})
export default class LogIn extends Vue {
    @Ref()
    private recapture!:NoRobot;
    private showLoginError: boolean = false;
    private valid: boolean = true;
    private email: string = "";
    private password: string = "";
    private emailRules = [
        value => !!value || this.$tc('register.new.validation.emailRequired'),
        value => !value || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) ||this.$tc('register.new.validation.emailValid')];
    private passWordRules = [
       value => !!value || this.$tc('register.new.validation.passwordRequired')
    ];
    private showPassword: boolean = false;
    private loading: boolean = false;

    mounted() {
        this.valid = false;
    }

    private async validate() {
        //@ts-ignore
        if (this.$refs.registerForm.validate()) {
            try {
                this.loading = true;

                const result = await this.recapture.validate();

                if (result) {
                    await this.logIn(this.email, this.password);
                } else {
                    await this.recapture.reset();
                }
            }finally {
                this.loading = false;
            }
        }
    }

    private async logIn(email: string, pass: string, silent: boolean = false) {
        const device = BrowserUtility.getDeviceConfig(navigator, window);
        const response = await this.$webApi.login(email, pass);
        if (!response) {
            if (!silent) {
                this.showLoginError = true;
                await this.recapture.reset();
            }
        } else {
            if (device.isStandalone) {
                this.$store.commit("SET_SAAPPUS", btoa(JSON.stringify({email: email, pass: pass, sid: response})));
            }

            setSid(response);
            const settings = await this.$webApi.getUserSettings();
            this.$store.commit("SET_DARKMODE",settings.darkMode);
            this.$store.commit("SET_LANGUAGE",settings.lang);

            await this.$router.go(0);
        }
    }
}
